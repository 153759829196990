import "./projectCards.scss";

export default function ProjectCards(props){
    return(
        <div className="projectcards">
            <img src={props.img} alt="projects"/>
            <h2 className="props-heading">{props.label}</h2>
            <div className="button-div">
                <a href={props.link} target="blank">
                <button className="live-site">
                    VIEW LIVE SITES
                </button>

                </a>
                <a href={props.github} target="blank">

                <button className="git-repo">
                    SEE GIT REPO
                </button>
                </a>
            </div>

            
        </div>
    )
}
import React from "react";
import "./about.scss";
import AboutImg from "../../img/about-img.png";
import { HashLink } from "react-router-hash-link";

export default function About() {
  return (
    <div className="about-section" id="about">
      <img src={AboutImg} alt="about-img" />
      <div className="about-text">
        <h1>About Me</h1>
        <p>
          My name is Ejembi Peter, I'm an experienced React Frontend and Backend
          developer. I'm currently working as a freelancer.I develop responsive
          and functional websites and web applications.
        </p>
        <HashLink smooth to="#contact-me">
          <button>LET'S TALK</button>
        </HashLink>
      </div>
    </div>
  );
}

import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import "./contact.scss";
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
export default function Contact() {
  const form = useRef();

  const notify = () => toast("Message sent!");
const errorText = () => toast("Error! Message not sent!");

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_cpjpdql",
        "template_nqcz34w",
        form.current,
        "uaoS2aElpLkC8zJhT"
      )
      .then(
        (result) => {
          console.log(result.text);
          console.log("message sent");
          notify();
          // Clears form/inputs after button is Selected
          form.current.reset();
        },
        (error) => {
          console.log(error.text);
          errorText();
        }
      );
  };

  return (
    <div className="contact-section" id="contact-me">
      <h1>Contact Me</h1>
      <div className="contact-box">
        <ToastContainer/>
        <div className="contact-text">
          <h1 className="heading">Have an awesome idea? Let’s discuss</h1>
          <p className="paragraph">
            Send me a message if you have different requirements
          </p>
        </div>
        <form className="input-fields" ref={form} onSubmit={sendEmail}>
          <input
            type="text"
            placeholder="Name"
            name="user_name"
          />
          <br />
          <input
            type="e-mail"
            placeholder="Email"
            name="user_email"
          />
          <br />
          <textarea
            type="text"
            placeholder="Message"
            name="message"
          />
          <button>SEND MESSAGE</button>
        </form>
      </div>
    </div>
  );
}

import React from "react";
import About from "./components/About/About";
import Contact from "./components/Contact/Contact";
import Footer from "./components/Footer/Footer";
import Hero from "./components/Hero/Hero";
import Navbar from "./components/Navbar/Navbar";
import Portfolio from "./components/Portfolio/Portfolio";
// import Pricing from "./components/Pricing/Pricing";
import Skills from "./components/skills/Skills";
import { Helmet } from "react-helmet";

function App() {
  return (
    <div className="App">
      <Helmet>
        <title>Jaypee's Portfolio</title>
        <meta name="description" content="Portfolio project" />
        <meta name="theme-color" content="#008f68" />
      </Helmet>
      <Navbar />
      <Hero />
      <Skills />
      <About />
      <Portfolio />
      {/* <Pricing/> */}
      <Contact />
      <Footer />
    </div>
  );
}

export default App;

import React from "react";
import "./navbar.scss";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import JPLogo from "../../img/jp-logo.png";
import { NavHashLink } from "react-router-hash-link";
import { useLocation } from "react-router-dom";
import EjembiPeter from "../../img/Ejembi Peter.pdf";

export default function Navbar() {
  const [isMobile, setIsMobile] = React.useState(false);
  const { hash } = useLocation();
  const isActive = (iHash) => hash === iHash;

  function toggleIsMobile() {
    setIsMobile((prevMobile) => !prevMobile);
  }
  return (
    <div className="navbar-container">
      <div className="navbar">
        <img src={JPLogo} alt="logo" />
        <ul>
          <li className="navigation-links">
            <NavHashLink
              smooth
              spy
              to="#home"
              className="navlink"
              activeClass="active"
              style={
                isActive("#home")
                  ? {
                      color: "white",
                      background:
                        "linear-gradient(92.11deg, #6A35F1 25.9%, #BC3FFE 84.84%)",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                      backgroundClip: "text",
                    }
                  : {}
              }
              activeStyle={{
                fontWeight: "bold",
                color: "white",
              }}
            >
              HOME
            </NavHashLink>
          </li>
          <li className="navigation-links">
            <NavHashLink
              smooth
              spy
              to="#about"
              className="navlink"
              activeClass="active"
              style={
                isActive("#about")
                  ? {
                      color: "white",
                      background:
                        "linear-gradient(92.11deg, #6A35F1 25.9%, #BC3FFE 84.84%)",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                      backgroundClip: "text",
                    }
                  : {}
              }
              activeStyle={{
                fontWeight: "bold",
                color: "white",
              }}
            >
              ABOUT ME
            </NavHashLink>
          </li>
          <li className="navigation-links">
            <NavHashLink
              smooth
              spy
              to="#portfolio"
              className="navlink"
              activeClass="active"
              style={
                isActive("#portfolio")
                  ? {
                      color: "white",
                      background:
                        "linear-gradient(92.11deg, #6A35F1 25.9%, #BC3FFE 84.84%)",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                      backgroundClip: "text",
                    }
                  : {}
              }
              activeStyle={{
                fontWeight: "bold",
                color: "white",
              }}
            >
              PORTFOLIO
            </NavHashLink>
          </li>
          {/* <li className="navigation-links">
            <NavHashLink
              smooth
              spy
              to="#pricing"
              className="navlink"
              activeClass="active"

              style={
                isActive("#pricing")
                  ? {
                      color: "white",
                      background:
                        "linear-gradient(92.11deg, #6A35F1 25.9%, #BC3FFE 84.84%)",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                      backgroundClip: "text",
                    }
                  : {}
              }
              activeStyle={{
                fontWeight: "bold",
                color: "white",
              }}
            >
              PRICING
            </NavHashLink>
          </li> */}
          <li className="navigation-links">
            <NavHashLink
              smooth
              spy
              to="#contact-me"
              className="navlink"
              activeClass="active"
              style={
                isActive("#contact-me")
                  ? {
                      color: "white",
                      background:
                        "linear-gradient(92.11deg, #6A35F1 25.9%, #BC3FFE 84.84%)",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                      backgroundClip: "text",
                    }
                  : {}
              }
              activeStyle={{
                fontWeight: "bold",
                color: "white",
              }}
            >
              CONTACT ME
            </NavHashLink>
          </li>
        </ul>
        <a href={EjembiPeter} download="Ejembi Peter.pdf">
          <button className="cv">DOWNLOAD CV</button>
        </a>
        {isMobile ? (
          <div className="mobile-x-case">
            <CloseIcon className="mobile-x" onClick={toggleIsMobile} />
          </div>
        ) : (
          <div className="mobile-hamburger-case">
            <MenuIcon className="mobile-hamburger" onClick={toggleIsMobile} />
          </div>
        )}
        {isMobile ? (
          <div className="navigation-mobile">
            <ul>
              <li className="mobile-li-1">
                <NavHashLink
                  smooth
                  to="#home"
                  className="navlink"
                  style={
                    isActive("#home")
                      ? {
                          color: "white",
                          background:
                            "linear-gradient(92.11deg, #6A35F1 25.9%, #BC3FFE 84.84%)",
                          WebkitBackgroundClip: "text",
                          WebkitTextFillColor: "transparent",
                          backgroundClip: "text",
                        }
                      : {}
                  }
                  activeStyle={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  HOME
                </NavHashLink>
              </li>
              <li className="mobile-li-2">
                <NavHashLink
                  smooth
                  to="#about"
                  className="navlink"
                  style={
                    isActive("#about")
                      ? {
                          color: "white",
                          background:
                            "linear-gradient(92.11deg, #6A35F1 25.9%, #BC3FFE 84.84%)",
                          WebkitBackgroundClip: "text",
                          WebkitTextFillColor: "transparent",
                          backgroundClip: "text",
                        }
                      : {}
                  }
                  activeStyle={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  ABOUT ME
                </NavHashLink>
              </li>
              <li className="mobile-li-3">
                <NavHashLink
                  smooth
                  to="#portfolio"
                  className="navlink"
                  style={
                    isActive("#portfolio")
                      ? {
                          color: "white",
                          background:
                            "linear-gradient(92.11deg, #6A35F1 25.9%, #BC3FFE 84.84%)",
                          WebkitBackgroundClip: "text",
                          WebkitTextFillColor: "transparent",
                          backgroundClip: "text",
                        }
                      : {}
                  }
                  activeStyle={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  PORTFOLIO
                </NavHashLink>
              </li>
              {/* <li className="mobile-li-4">
                <NavHashLink
                  smooth
                  to="#pricing"
                  className="navlink"
                  style={
                    isActive("#pricing")
                      ? {
                          color: "white",
                          background:
                            "linear-gradient(92.11deg, #6A35F1 25.9%, #BC3FFE 84.84%)",
                          WebkitBackgroundClip: "text",
                          WebkitTextFillColor: "transparent",
                          backgroundClip: "text",
                        }
                      : {}
                  }
                  activeStyle={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  PRICING
                </NavHashLink>
              </li> */}
              <li className="mobile-li-5">
                <NavHashLink
                  smooth
                  to="#contact-me"
                  className="navlink"
                  style={
                    isActive("#contact-me")
                      ? {
                          color: "white",
                          background:
                            "linear-gradient(92.11deg, #6A35F1 25.9%, #BC3FFE 84.84%)",
                          WebkitBackgroundClip: "text",
                          WebkitTextFillColor: "transparent",
                          backgroundClip: "text",
                        }
                      : {}
                  }
                  activeStyle={{
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  CONTACT ME
                </NavHashLink>
              </li>
            </ul>
            <a href={EjembiPeter} download="Ejembi Peter.pdf">
              <button className="cv-mobile">DOWNLOAD CV</button>
            </a>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

import "./footer.scss";
import { Instagram, Twitter, Facebook, WhatsApp } from "@mui/icons-material";
export default function Footer() {
  return (
    <div className="footer-section">
      <div className="footer">
        <h5>Find me on social media</h5>
        <div className="social-media-icons">
          <a href="https://www.instagram.com/jaypeeter/" target="blank">
            <Instagram className="icons" />
          </a>
          <a href="https://twitter.com/EjembiPeter10" target="blank">
            <Twitter className="icons" />
          </a>
          <a
            href="https://web.facebook.com/profile.php?id=100007537819104"
            target="blank"
          >
            <Facebook className="icons" />
          </a>
          <a
            href="https://api.whatsapp.com/send?phone=2348134325735&text=I%20would%20like%20you%20to%20build"
            target="blank"
          >
            <WhatsApp className="icons" />
          </a>
        </div>
        <p>©2022 Jaypee. All rights reserved</p>
      </div>
    </div>
  );
}

import React from "react";
import "./skills.scss";
import Node from "../../img/node logo.png";
import MongoDB from "../../img/mongodb logo.png";
import Js from "../../img/Js.png";
import Bootstrap from "../../img/Bootstrap.png";
import ReactImg from "../../img/React.png";

export default function Skills(){
    return(
        <div className="skills-section">
            <div className="h1-container">
            <h1>Top Skills</h1>
            </div>
            <div className="top-skills">
                <img src={Node} alt="html" className="node"/>
                <img src={MongoDB} alt="css" className="mongodb"/>
                <img src={Js} alt="js"/>
                <img src={ReactImg} alt="react-img"/>
                <img src={Bootstrap} alt="bootstrap"/>
                
            </div>
            <div className="top-skills-mobile">
                <div className="top-row">
                <img src={Js} alt="js"/>
                <img src={Node} alt="html" className="node"/>
                <img src={MongoDB} alt="css" className="mongodb"/>
                </div>
                <div className="bottom-row">
                <img src={ReactImg} alt="react-img"/>
                <img src={Bootstrap} alt="bootstrap"/>

                </div>
            </div>
            
        </div>
    )
}
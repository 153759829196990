import React from "react";
import "./hero.scss";
import JaypeesProfile from "../../img/profile.png";
import { HashLink } from "react-router-hash-link";

export default function Hero() {
  return (
    <div className="hero-section" id="home">
      <div className="hero-text">
        <p className="hello">Hello I'm</p>
        <h1 className="my-name">Ejembi Peter</h1>
        <h1 className="website-dev">Website Developer</h1>
        <p className="i-take">
          I take outstanding ideas from concept to world class software
        </p>
        <div className="buttons">
          <HashLink smooth to="#contact-me">
            <button className="hire-me">HIRE ME</button>
          </HashLink>
          <HashLink smooth to="#portfolio">
            <button className="portfolio">PORTFOLIO</button>
          </HashLink>
        </div>
      </div>
      <div className="profile-pic-container">
        <img src={JaypeesProfile} className="profile-pic" alt="profile" />
      </div>
    </div>
  );
}

import React from "react";
import "./portfolio.scss";
import ProjectCards from "../../widgets/Projectfiles/ProjectCards";
import CamelsbackProject from "../../img/Camelsback-project.png";
import DashAdmin from "../../img/Jaypee-dashadmin_mockup.png";
import WhosLying from "../../img/Jaypee-Whoislying_Mockup.png";
import OpenAi from "../../img/Jaypee-openaitech_mockup.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        background: "linear-gradient(92.11deg, #6A35F1 25.9%, #BC3FFE 84.84%)",
        borderRadius: "50%",
      }}
      onClick={onClick}
    />
  );
}
function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        background: "linear-gradient(92.11deg, #6A35F1 25.9%, #BC3FFE 84.84%)",
        borderRadius: "50%",
      }}
      onClick={onClick}
    />
  );
}
class Portfolio extends React.Component {
  render() {
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
      initialSlide: 0,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <div className="portfolio-section" id="portfolio">
        <h1>Portfolio</h1>
        <Slider {...settings}>
          <ProjectCards
            img={CamelsbackProject}
            label="Camel's Back Logistics"
            link="https://camelsback-logistics.netlify.app"
            github="https://github.com/Jaypeeter/Camelsback-logistics"
          />
          <ProjectCards
            img={WhosLying}
            label="Whoislying Website"
            github="https://github.com/Jaypeeter/who-is-lying"
          />
          <ProjectCards
            img={DashAdmin}
            label="Dashadmin Website"
            link="https://dashadmin-web.netlify.app"
            github="https://github.com/Jaypeeter/Dashadmin-business"
          />
          <ProjectCards
            img={OpenAi}
            label="OpenAI Technologies"
            link="https://openai-tech.netlify.app"
            github="https://github.com/Jaypeeter/B-B-website-template"
          />
        </Slider>
      </div>
    );
  }
}

export default Portfolio;
